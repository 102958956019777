import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import { CategoryService } from '../../services/category.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
    user:any;
    isSuperAdmin:boolean=false;
    isDataSteward:boolean=false;
    isUser:boolean=false;
    categories:any[]=[];

    constructor(
        public router: Router,
        private translateService:TranslateService,
        private categoryService:CategoryService,
        private authService:AuthService
    ) { }

    ngOnInit(): void {
        this.listField();
        this.user = this.authService.getUser();
        if(this.user != null){
            this.isSuperAdmin =  this.user.isSuperAdmin;
            this.isDataSteward =  this.user.isDataSteward;
            this.isUser =  this.user.isUser;
        }
      
    }

    listField(){
        this.categoryService.listpublic().subscribe({
            next: (resp) => {
                this.categories = resp.data;
            }, error: (error) => {

            }
        })
      }

    switcherClassApplied = false;
    switcherToggleClass() {
        this.switcherClassApplied = !this.switcherClassApplied;
    }

    searchClassApplied = false;
    searchToggleClass() {
        this.searchClassApplied = !this.searchClassApplied;
    }

    classApplied = false;
    toggleClass() {
        this.classApplied = !this.classApplied;
    }

    changeLang(lang:string){
        localStorage.setItem("lang",lang);
        this.translateService.setDefaultLang(localStorage.getItem("lang") ?? 'en');
        this.translateService.use(localStorage.getItem("lang") ?? 'en');
        
    }

}