// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // Production API
  apiURL: "https://api.mosp.gov.my/api",
  keyCaptcha:"6LdPSdMlAAAAAI2pfNY26iMSd7StdjIe79Drzfle",
  // apiURL: "http://18.183.99.60:8082/api", // DEPRECATED
  // Development API
  // apiURL: "http://18.183.45.65:8082/api",
  // apiURL: "http://localhost:8082/api",
  
  defaultauth: 'fackbackend',
  firebaseConfig: {
    apiKey: '',
    authDomain: '',
    databaseURL: '',
    projectId: '',
    storageBucket: '',
    messagingSenderId: '',
    appId: '',
    measurementId: ''
  }
};

export const GTM_ID = 'GTM-P2WG9KSX';

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
