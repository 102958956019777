import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { CategoryService } from '../../services/category.service';
import {SavedSearchService} from "../../services/saved-search.service";

@Component({
  selector: 'app-top-header',
  templateUrl: './top-header.component.html',
  styleUrls: ['./top-header.component.scss']
})
export class TopHeaderComponent implements OnInit {

  user: any;
  isSuperAdmin: boolean = false;
  isDataSteward: boolean = false;
  isUser: boolean = false;
  categories: any[] = [];

  constructor(public router: Router,
              private authService: AuthService,
              private translateService: TranslateService,
              private categoryService:CategoryService,
              private savedSearchService: SavedSearchService) { }

  ngOnInit(): void {
    // this.listField();
    this.user = this.authService.getUser();
    if (this.user != null) {
      this.isSuperAdmin = this.user.isSuperAdmin;
      this.isDataSteward = this.user.isDataSteward;
      this.isUser = this.user.isUser;
    }
    this.user = this.authService.getUser();
    // console.log("user",this.user)
  }

  profile() {
    if (this.user.isUser) {
      this.router.navigate(["/account/favourite"]);
    } else {
      this.router.navigate(["/account/dashboard"]);
    }
  }
  logout() {
    localStorage.removeItem("user");
    window.location.href = "/login"
    // this.router.navigate(['/']);
  }


  changeLang(lang: string) {
    localStorage.setItem("lang", lang);
    this.translateService.setDefaultLang(localStorage.getItem("lang") ?? 'en');
    this.translateService.use(localStorage.getItem("lang") ?? 'en');

  }

  switcherClassApplied = false;
  switcherToggleClass() {
    this.switcherClassApplied = !this.switcherClassApplied;
  }

  searchClassApplied = false;
  searchToggleClass() {
    this.searchClassApplied = !this.searchClassApplied;
  }

  classApplied = false;

  toggleClass() {
    this.classApplied = !this.classApplied;
    this.resetSavedSearch();
  }

  resetSavedSearch() {
    this.savedSearchService.searchState = null;
    localStorage.removeItem('catId');
  }
}
