import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SavedSearchService {

  private lastSearch: any;

  constructor() { }

  get searchState() {
    return this.lastSearch;
  }

  set searchState(lastSearch: any) {
    this.lastSearch = lastSearch;
  }
}
