import { Component , OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit  {

  constructor(
    private translate: TranslateService
)
{ 

  console.log = function (): void { };
  console.debug = function (): void { };
  console.warn = function (): void { };
  console.info = function (): void { };
  
  translate.addLangs(['en', 'my']);
    translate.setDefaultLang(localStorage.getItem("lang") ? localStorage.getItem("lang") : 'en');
    translate.use(localStorage.getItem("lang") ? localStorage.getItem("lang") : 'en');
}

  ngOnInit() {
    // document.getElementsByTagName("html")[0].setAttribute("dir", "rtl");
  }
}
