<div id="layout-wrapper">
  <app-horizontaltopbar></app-horizontaltopbar>

  <div class="main-content">
    <div class="page-content">
      <div class="container-fluid">
        <router-outlet></router-outlet>
      </div>
      
    </div>
  </div>

  <app-footer></app-footer>
  <app-rightsidebar></app-rightsidebar>
</div>
