import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

import { from as fromPromise,Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import {HttpClient,HttpHeaders} from "@angular/common/http";


@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  public static MONTHS = ["January","February","March","April","May","June","July","August","September","October","November","December"];
  private APIURL = `${environment.apiURL}/auth/dashboard`;
  private APIURL_DIAGNOSE = `${environment.apiURL}/auth/harvester/diagnose`;
  private APIURL_STATISTIC = `${environment.apiURL}/auth/statistic?type=search`;
  private APIURL_SUBJECT = `${environment.apiURL}/auth/subject`;
  private APIURL_KEYWORDS = `${environment.apiURL}/auth/statistic`;
  private APIURL_WEBSITE = `${environment.apiURL}/auth/contact`;
  private APIURL_PUBLIC = `${environment.apiURL}/public`;

  constructor(public httpClient: HttpClient) { this.httpClient = httpClient; }

  list(param): Observable<any> {
    return this.httpClient.get(`${this.APIURL}/admin?${param}`)
    .pipe(
      catchError((err => {
        return this.handleError(err);
      })
    ));
  }

  listSteward(): Observable<any> {
    return this.httpClient.get(`${this.APIURL}/steward`)
    .pipe(
      catchError((err => {
        return this.handleError(err);
      })
    ));
  }

  homeDashboard(){
    return this.httpClient.get(`${environment.apiURL}/public/dashboard`)
    .pipe(
      catchError((err => {
        return this.handleError(err);
      })
    ));
  }

  announcement(){
    return this.httpClient.get(`${environment.apiURL}/public/announcement`)
    .pipe(
      catchError((err => {
        return this.handleError(err);
      })
    ));
  }

  slider(){
    return this.httpClient.get(`${environment.apiURL}/public/slider`)
    .pipe(
      catchError((err => {
        return this.handleError(err);
      })
    ));
  }

  footer(){
    return this.httpClient.get(`${environment.apiURL}/public/dashboard/footer`)
    .pipe(
      catchError((err => {
        return this.handleError(err);
      })
    ));
  }

  diagnose(){
    return this.httpClient.get(`${this.APIURL_DIAGNOSE}`)
    .pipe(
      catchError((err => {
        return this.handleError(err);
      })
    ));
  }

  diagnoseByAgency(id:number,month:any,year:any){
    let monthpath="";
    let yearpath=""
    if(month){
      monthpath=`/month/${month}`
    }

    if(year){
      yearpath=`/year/${year}`
    }
    
    return this.httpClient.get(`${this.APIURL_DIAGNOSE}/agency/${id}${yearpath}${monthpath}`)
    .pipe(
      catchError((err => {
        return this.handleError(err);
      })
    ));
  }
  

  statistic(){
    return this.httpClient.get(`${this.APIURL_STATISTIC}`)
    .pipe(
      catchError((err => {
        return this.handleError(err);
      })
    ));
  }

  getAllSubjects(param): Observable<any> {
    return this.httpClient.get(this.APIURL_SUBJECT+'?'+param).pipe(
        catchError((err => {
          return this.handleError(err);
        }))
    );
  }

  toggleSubjectVisibility(id, params): Observable<any> {
    return this.httpClient.put(this.APIURL_SUBJECT+'/'+id, params).pipe(
        catchError((err => {
          return this.handleError(err);
        }))
    );
  }

  getSearchKeywords(search: string): Observable<any> {
    return this.httpClient.get(`${this.APIURL_KEYWORDS+'?'+search}`).pipe(
      catchError((err => {
        return this.handleError(err);
      }))
    );
  }

  toggleSearchedKeywordsVisibility(id: any, params: any): Observable<any> {
    return this.httpClient.put(`${this.APIURL_KEYWORDS}/${id}`, params).pipe(
      catchError((err => {
        return this.handleError(err);
      }))
    );
  }

  getListWebsiteInfo(): Observable<any> {
    return this.httpClient.get(`${this.APIURL_WEBSITE}`).pipe(
      catchError((err => {
        return this.handleError(err);
      }))
    );
  }

  addContentData(param: any): Observable<any> {
    return this.httpClient.post(`${this.APIURL_WEBSITE}`, param).pipe(
      catchError((err => {
        return this.handleError(err);
      }))
    );
  }

  getContentInfoById(id: any): Observable<any> {
    return this.httpClient.get(`${this.APIURL_WEBSITE}/${id}`,).pipe(
      catchError((err => {
        return this.handleError(err);
      }))
    );
  }

  updateContentInfo(id: any, param: any): Observable<any> {
    return  this.httpClient.put(`${this.APIURL_WEBSITE}/${id}`, param).pipe(
      catchError((err => {
        return this.handleError(err);
      }))
    );
  }

  getFooter(): Observable<any> {
    return this.httpClient.get(`${this.APIURL_PUBLIC}/contact`).pipe(
      catchError((err => {
        return this.handleError(err);
      }))
    );
  }

  getAllDatasetViews(): Observable<any> {
    return this.httpClient.get(`${this.APIURL}/admin/allHarvester`).pipe(
      catchError((err => {
        return this.handleError(err);
      }))
    );
  }

  private handleError(error: any): Observable<any> {
    return throwError(error);
  }
}
