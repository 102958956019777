import { Component } from '@angular/core';

@Component({
  selector: 'app-mosp',
  templateUrl: './mosp.component.html',
  styleUrls: ['./mosp.component.scss']
})
export class MospComponent  {

  show:boolean=true;
  constructor()
{ 
}


  ngOnInit() {
   
    let path = window.location.pathname;

    if(path == "/harvester-detail" || path == "/print-audittrail"){
        this.show =false;
     }else{
         this.show = true;
     }
  }


}
