<div class="top-header-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-3 col-md-12">
                <a class="navbar-brand" routerLink="/" (click)="resetSavedSearch()">
                    <!-- <img src="assets/images/logo.png" alt="logo"> -->

                    <img src="assets/images/MOSTI-Logo-2.png" alt="logo" width="70" class="me-2 logo-others">

                    <img src="assets/images/asm-logo.png" alt="logo" width="75" class="logo-others me-2">

                    <img src="assets/images/MOSP-logo-final.png" alt="logo" width="100" >

                </a>
            </div>
            <div class="col-lg-9 col-md-12" >
                <div class="header-right-content">
                    <!-- <li>
                        Welcome to Lian University
                    </li> -->
                    <li>
                        <a routerLink="/login" *ngIf="user == null;else log">
                            <i class="ri-login-box-line"></i>
                            {{'Login' | translate}}
                        </a>
                        <ng-template #log>
                            <a routerLink="javascript:void(0)" (click)="logout()">
                                <i class="ri-login-box-line"></i>
                                {{'Logout' | translate}}
                            </a>
                        </ng-template>
                    </li>
                    <li>
                        <a routerLink="/register" *ngIf="user == null;else username">
                            <i class="ri-file-user-line"></i>
                            {{'Register' | translate}}
                        </a>
                        <ng-template #username>
                            <a href="javascript:void(0)" (click)="profile()">
                                <i class="ri-user-line"></i>
                                {{user?.username}}
                            </a>
                        </ng-template>
                    </li>
                    <li style="margin-right: 6px;">
                        <a href="javascript:void(0)" (click)="changeLang('my')">MY </a> &nbsp; | &nbsp; <a
                            href="javascript:void(0)" (click)="changeLang('en')" class="p-0">EN</a>

                    </li>
                    <li class="navbar-light">
                            <button class="navbar-toggler" type="button" (click)="toggleClass()">
                                <span class="burger-menu">
                                    <span class="top-bar"></span>
                                    <span class="middle-bar"></span>
                                    <span class="bottom-bar" style="margin-bottom: 0px;"></span>
                                </span>
                            </button>
                      
                    </li>
                </div>
                <!-- Navbar -->
                <!-- stickyClass="sticky-box-shadow" ngStickyNav -->
                <div class="navbar-area" >
                    <div class="container" style="margin-right: 0; max-width: none !important;">
                        <nav class="navbar navbar-expand-lg navbar-light bg-light" [class.active]="classApplied">
                            <!-- <button class="navbar-toggler" type="button" (click)="toggleClass()">
                                <span class="burger-menu">
                                    <span class="top-bar"></span>
                                    <span class="middle-bar"></span>
                                    <span class="bottom-bar"></span>
                                </span>
                            </button> -->
                           
                            <div class="collapse navbar-collapse">
                                <ul class="navbar-nav ">
                                    <!-- <li class="nav-item">
                                        <a routerLink="/" class="nav-link" routerLinkActive="active"
                                            [routerLinkActiveOptions]="{exact: true}"> {{'Home' | translate}}</a>
                                    </li> -->

                                    <li class="nav-item">
                                        <a routerLink="/about" class="nav-link" routerLinkActive="active"
                                            [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">{{'About'
                                            | translate}}</a>
                                    </li> 
                                    <li class="nav-item">
                                        <a routerLink="/announcement" class="nav-link" routerLinkActive="active"
                                            [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">{{'News'
                                            | translate}}</a>
                                    </li> 
                                    <li class="nav-item">
                                        <a routerLink="/repositories" class="nav-link" routerLinkActive="active"
                                            [routerLinkActiveOptions]="{exact: true}"
                                            (click)="toggleClass()">{{'Provider' | translate}}</a>
                                    </li>
                                    <li class="nav-item">
                                        <a routerLink="/research-field" class="nav-link" routerLinkActive="active"
                                            [routerLinkActiveOptions]="{exact: true}"
                                            (click)="toggleClass()">{{'Research Field' | translate}}</a>
                                    </li>
                                    <li class="nav-item">
                                        <a routerLink="/stewards" class="nav-link" routerLinkActive="active"
                                            [routerLinkActiveOptions]="{exact: true}"
                                            (click)="toggleClass()">{{'Data Stewards' | translate}}</a>
                                    </li>
                                    <!-- <li class="nav-item">
                                        <a routerLink="/repository-detail" class="dropdown-toggle nav-link">{{'Research Field' |
                                            translate}}</a>
                                        <ul class="dropdown-menu">
                                            <li class="nav-item" *ngFor="let i of categories">
                                                <a href="/repository-detail?type=category&id={{i?.id}}" class="nav-link"
                                                    routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
                                                    (click)="toggleClass()">{{i?.name}}</a>
                                            </li>
                                        </ul>
                                    </li> -->
                                    <!--  ##### data steward #######-->
                                    <li class="nav-item" *ngIf="isDataSteward">
                                        <a routerLink="/account/dashboard" class="nav-link" routerLinkActive="active"
                                            [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">{{'Agency Admin' | translate}}</a>
                                    </li>
                                    <!--  ##### user #######-->
                                    <li class="nav-item" *ngIf="isUser">
                                        <a routerLink="/account/favourite" class="nav-link" routerLinkActive="active"
                                            [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">{{'User' | translate}}</a>
                                    </li>

                                    <!--  ##### superadmin #######-->
                                    <li class="nav-item" *ngIf="isSuperAdmin">
                                        <a routerLink="/account/dashboard" class="nav-link" routerLinkActive="active"
                                            [routerLinkActiveOptions]="{exact: true}"
                                            (click)="toggleClass()">{{'Superadmin' | translate}}</a>
                                    </li>
                                    <li class="nav-item">
                                        <a routerLink="/contact" class="nav-link" routerLinkActive="active"
                                            [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">{{'Contact Us' | translate}}</a>
                                    </li>
                                </ul>

                                <!-- ############################ mobile############################ -->
                                <ul class="navbar-nav for-responsive">
                                    <li class="nav-item">
                                        <a routerLink="/about" class="nav-link" routerLinkActive="active"
                                            [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">{{'About'
                                            | translate}}</a>
                                    </li>
                                    <li class="nav-item">
                                        <a routerLink="/announcement" class="nav-link" routerLinkActive="active"
                                            [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">{{'News'
                                            | translate}}</a>
                                    </li> 
                                    <li class="nav-item">
                                        <a routerLink="/repositories" class="nav-link" routerLinkActive="active"
                                            [routerLinkActiveOptions]="{exact: true}"
                                            (click)="toggleClass()">{{'Provider' | translate}}</a>
                                    </li>
                                    <!-- <li class="nav-item">
                                        <a routerLink="/search" class="dropdown-toggle nav-link">{{'Field for Research'
                                            | translate}}</a>
                                        <ul class="dropdown-menu">
                                            <li class="nav-item" *ngFor="let i of categories">
                                                <a href="javascript:void(0)" class="nav-link" routerLinkActive="active"
                                                    [routerLinkActiveOptions]="{exact: true}"
                                                    (click)="toggleClass()">{{i?.name}}</a>
                                            </li>
                                        </ul>
                                    </li> -->
                                    <li class="nav-item">
                                        <a routerLink="/research-field" class="nav-link" routerLinkActive="active"
                                            [routerLinkActiveOptions]="{exact: true}"
                                            (click)="toggleClass()">{{'Research Field' | translate}}</a>
                                    </li>
                                    <li class="nav-item">
                                        <a routerLink="/stewards" class="nav-link" routerLinkActive="active"
                                            [routerLinkActiveOptions]="{exact: true}"
                                            (click)="toggleClass()">{{'Data Stewards' | translate}}</a>
                                    </li>
                                    <li class="nav-item" *ngIf="isDataSteward">
                                        <a routerLink="/account/dashboard" class="nav-link" routerLinkActive="active"
                                            [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">{{'Agency
                                            Admin' | translate}}</a>
                                    </li>
                                    <li class="nav-item" *ngIf="isUser">
                                        <a routerLink="/account/favourite" class="nav-link" routerLinkActive="active"
                                            [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">{{'User' |
                                            translate}}</a>
                                    </li>
                                    <li class="nav-item" *ngIf="isSuperAdmin">
                                        <a routerLink="/account/dashboard" class="nav-link" routerLinkActive="active"
                                            [routerLinkActiveOptions]="{exact: true}"
                                            (click)="toggleClass()">{{'Superadmin' | translate}}</a>
                                    </li>
                                    <li class="nav-item">
                                        <a routerLink="/contact" class="nav-link" routerLinkActive="active"
                                            [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">{{'Contact
                                            Us' | translate}}</a>
                                    </li>
                                </ul>

                            </div>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>