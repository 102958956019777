<footer class="footer">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-6">
                {{year}} © <a href="https://www.akademisains.gov.my/" target="_blank">Malaysia Open Science Platform (MOSP)</a>. {{ 'All Rights Reserved' | translate}}
            </div>
            <div class="col-sm-6">
                <div class="text-sm-end d-none d-sm-block">
                    {{ 'privacy-policy' | translate }}
                </div>
            </div>
        </div>
    </div>
</footer>