import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

import { from as fromPromise,Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import {HttpClient,HttpHeaders} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  private APIURL = `${environment.apiURL}/auth`;

  constructor(public httpClient: HttpClient) { this.httpClient = httpClient; }


  list(): Observable<any> {
    return this.httpClient.get(`${this.APIURL}/category`)
    .pipe(
      catchError((err => {
        return this.handleError(err);
      })
    ));
  }

  listpublic(): Observable<any> {
    return this.httpClient.get(`${environment.apiURL}/public/category`)
    .pipe(
      catchError((err => {
        return this.handleError(err);
      })
    ));
  }

  detailpublic(id:number): Observable<any> {
    return this.httpClient.get(`${environment.apiURL}/public/category/${id}`)
    .pipe(
      catchError((err => {
        return this.handleError(err);
      })
    ));
  }

  detail(id:number): Observable<any> {
    return this.httpClient.get(`${this.APIURL}/category/${id}`)
    .pipe(
      catchError((err => {
        return this.handleError(err);
      })
    ));
  }

  delete(id:number): Observable<any> {
    return this.httpClient.delete(`${this.APIURL}/category/${id}`)
    .pipe(
      catchError((err => {
        return this.handleError(err);
      })
    ));
  }

  update(param:any,id:number): Observable<any> {
    return this.httpClient.put(`${this.APIURL}/category/${id}`,param)
    .pipe(
      catchError((err => {
        return this.handleError(err);
      })
    ));
  }

  add(param:any): Observable<any> {
    return this.httpClient.post(`${this.APIURL}/category`,param)
    .pipe(
      catchError((err => {
        return this.handleError(err);
      })
    ));
  }


  private handleError(error: any): Observable<any> {
    return throwError(error);
  }
}
