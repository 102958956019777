<div id="layout-wrapper">

  <app-topbar (settingsButtonClicked)="onSettingsButtonClicked()" (mobileMenuButtonClicked)="onToggleMobileMenu()" *ngIf="show">
  </app-topbar>

  <app-sidebar [isCondensed]="isCondensed" *ngIf="show"></app-sidebar>

  <div class="main-content"  *ngIf="show;else print">
    <div class="page-content">
      <!-- content -->
      <div class="container-fluid"> <router-outlet></router-outlet></div>
    </div>
    <!-- footer -->
    <app-footer></app-footer>
  </div>

  <ng-template #print>
    <div class="container-fluid"> <router-outlet></router-outlet></div>
  </ng-template>

</div>

<app-rightsidebar *ngIf="show"></app-rightsidebar>
