import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

import { from as fromPromise,Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import {HttpClient,HttpHeaders} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private APIURL = `${environment.apiURL}/auth`;

  constructor(public httpClient: HttpClient) { this.httpClient = httpClient; }


  login(param:any): Observable<any> {
    return this.httpClient.post(`${this.APIURL}/login`,param)
    .pipe(
      catchError((err => {
        return this.handleError(err);
      })
    ));
  }

  register(param:any): Observable<any> {
    return this.httpClient.post(`${this.APIURL}/register`,param)
    .pipe(
      catchError((err => {
        return this.handleError(err);
      })
    ));
  }

  forgot(param:any): Observable<any> {
    return this.httpClient.post(`${this.APIURL}/forgot`,param)
    .pipe(
      catchError((err => {
        return this.handleError(err);
      })
    ));
  }

  updateProfile(param:any): Observable<any> {
    return this.httpClient.put(`${this.APIURL}/register`,param)
    .pipe(
      catchError((err => {
        return this.handleError(err);
      })
    ));
  }

  updateProfileExternal(param:any,userId:number): Observable<any> {
    return this.httpClient.put(`${this.APIURL}/external/${userId}`,param)
    .pipe(
      catchError((err => {
        return this.handleError(err);
      })
    ));
  }

  updateProfileInternal(param:any,userId:number): Observable<any> {
    return this.httpClient.put(`${this.APIURL}/internal/${userId}`,param)
    .pipe(
      catchError((err => {
        return this.handleError(err);
      })
    ));
  }

  resetPwdAdmin(param:any): Observable<any> {
    return this.httpClient.post(`${this.APIURL}/resetadmin`,param)
    .pipe( 
      catchError((err => {
        return this.handleError(err);
      })
    ));
  }

  getUser(){
    return localStorage.getItem('user') != null ? JSON.parse(localStorage.getItem('user') ?? '') : null;
  }

  resetPwdUser(param:any): Observable<any> {
    return this.httpClient.post(`${this.APIURL}/reset`,param)
    .pipe(
      catchError((err => {
        return this.handleError(err);
      })
    ));
  }

  resetadmin(param:any): Observable<any> {
    return this.httpClient.post(`${this.APIURL}/resetadmin`,param)
    .pipe(
      catchError((err => {
        return this.handleError(err);
      })
    ));
  }

  adminActivateUser(id:any,status:any): Observable<any> {
    return this.httpClient.get(`${this.APIURL}/enable/${id}/status/${status}`)
    .pipe(
      catchError((err => {
        return this.handleError(err);
      })
    ));
  }
  
  preActivate(param:any){
    return this.httpClient.post(`${this.APIURL}/preactive`,param)
    .pipe(
      catchError((err => {
        return this.handleError(err);
      })
    ));
  }

  activate(param:any){
    return this.httpClient.post(`${this.APIURL}/active`,param)
    .pipe(
      catchError((err => {
        return this.handleError(err);
      })
    ));
  }
  
  private handleError(error: any): Observable<any> {
    return throwError(error);
  }
}
