import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
    {
        id: 1,
        label: 'Main',
        isTitle: true
    },
    {
        id: 2,
        label: 'Dashboard',
        icon: 'bx-bar-chart',
        link: '/account/dashboard',
    },
    {
        id: 3,
        label: 'Dataset',
        icon: 'bxs-briefcase-alt-2',
        link: '/account/data-harvester',
    },
    {
        id: 4,
        label: 'Contributor',
        icon: 'bx-table',
        link: '/account/agency',
    },{
        id: 70,
        label: 'Data Stewards',
        icon: 'bxs-user',
        link: '/account/steward-user',
    },{
        id: 60,
        label: 'Data Stewards Form',
        icon: 'bxs-user',
        link: '/account/steward-request',
    },
    {
        id: 5,
        label: 'Audit Trail',
        icon: 'bx-file-find',
        link: '/account/audittrail',
    },
    // {
    //     id: 55,
    //     label: 'Keywords',
    //     icon: 'bx-search-alt-2',
    //     link: '/account/keywords',
    // },
    {
        id: 62,
        label: 'Search Keywords',
        icon: 'bx-search',
        link: '/account/search-keywords',
    },
    {
        id: 5,
        label: 'Feedback',
        icon: 'bx-comment',
        link: '/account/feedback',
    },
    {
        id: 9,
        label: 'Repository Management',
        isTitle: true
    },
    {
        id: 10,
        label: 'Repository',
        icon: 'bxs-briefcase-alt-2',
        link: '/account/repository',
    },
    {
        id: 11,
        label: 'Research Field',
        icon: 'bx-box',
        link: '/account/category',
        
    },
    {
        id: 12,
        label: 'Metadata',
        icon: 'bx-code',
        link: '/account/metadata',
    },
    {
        id: 13,
        label: 'Protocol',
        icon: 'bxs-lock',
        link: '/account/protocol',
    },
    
    {
        id: 56,
        label: 'CMS',
        isTitle: true
    },
    {
        id: 60,
        label: 'Slider',
        icon: 'bxs-carousel',
        link: '/account/slider',
    },
    {
        id: 57,
        label: 'Web Content',
        icon: 'bx-book-content',
        link: '/account/web-content',
    },
    {
        id: 59,
        label: 'History',
        icon: 'bxs-receipt',
        link: '/account/history',
    },
    {
        id: 61,
        label: 'Announcement Category',
        icon: 'bx-list-ul',
        link: '/account/category-announcement',
    },
    {
        id: 58,
        label: 'Announcement',
        icon: 'bxs-megaphone',
        link: '/account/announcement',
    },
    {
        id: 14,
        label: 'User Management',
        isTitle: true
    },
    {
        id: 15,
        label: 'User',
        icon: 'bx-user',
        link: '/account/users',
    },
    {
        id: 15,
        label: 'Settings',
        isTitle: true
    },
    {
        id: 55,
        label: 'Popular Subject',
        icon: 'bx-cloud',
        link: '/account/word-cloud',
    },
    {
        id: 17,
        label: 'State',
        icon: 'bx-map',
        link: '/account/state',
    },
    {
        id: 63,
        label: 'Website',
        icon: 'bx-globe',
        link: '/account/settings',
    },
    
];

export const MENUSTEWARD: MenuItem[] = [
    {
        id: 1,
        label: 'Main',
        isTitle: true
    },
    {
        id: 2,
        label: 'Dashboard',
        icon: 'bx-bar-chart',
        link: '/account/dashboard',
    },
    {
        id: 3,
        label: 'Dataset',
        icon: 'bxs-briefcase-alt-2',
        link: '/account/data-harvester',
    },
    {
        id: 9,
        label: 'Repository Management',
        isTitle: true
    },
    {
        id: 10,
        label: 'Repository',
        icon: 'bxs-briefcase-alt-2',
        link: '/account/repository-steward',
    },
    {
        id: 1,
        label: 'Profile',
        isTitle: true
    },
    {
        id: 2,
        label: 'Edit Profile',
        icon: 'bxs-user-circle',
        link: '/account/profile',
    },
    {
        id: 2,
        label: 'Change Password',
        icon: 'bxs-lock',
        link: '/account/password',
    }
];

export const MENUUSER: MenuItem[] = [
    {
        id: 1,
        label: 'Main',
        isTitle: true
    },
    {
        id: 2,
        label: 'Favourite',
        icon: 'bx-bar-chart',
        link: '/account/favourite',
    },
    {
        id: 1,
        label: 'Profile',
        isTitle: true
    },
    {
        id: 2,
        label: 'Edit Profile',
        icon: 'bxs-user-circle',
        link: '/account/profile',
    },
    {
        id: 2,
        label: 'Change Password',
        icon: 'bxs-lock',
        link: '/account/password',
    }
];

