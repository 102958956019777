<header id="page-topbar">
  <div class="navbar-header">
    <div class="d-flex">
      <!-- LOGO -->
      <div class="navbar-brand-box">
        <a routerLink="/" class="logo logo-dark">
          <span class="logo-sm">
            <img src="assets/images/favicon-menu.png" alt="" style="height: 45px !important; width: auto">
          </span>
          <span class="logo-lg">
            <img src="assets/images/MOSP-logo-final.png" alt="" width="140">
          </span>
        </a>

        <a routerLink="/" class="logo logo-light">
          <span class="logo-sm">
            <img src="assets/images/favicon-menu.png" alt="" style="height: 45px !important; width: auto">
          </span>
          <span class="logo-lg">
            <img src="assets/images/MOSP-logo-final.png" alt="" width="140">
          </span>
        </a>
      </div>

      <button type="button" class="btn btn-sm px-3 font-size-16 header-item" id="vertical-menu-btn"
        (click)="toggleMobileMenu($event)">
        <i class="fa fa-fw fa-bars"></i>
      </button>

      <!-- App Search-->
      
    </div>

    <div class="d-flex">
  
      <div class=" d-none d-lg-inline-block ms-1" >
        <a  routerLink="/">
          <button type="button" class="btn header-item noti-icon">
            <i class="bx bx-home"></i>
          </button>
        </a>
    
      </div>



      <div class="dropdown d-none d-lg-inline-block ms-1">
        <button type="button" class="btn header-item noti-icon" data-toggle="fullscreen" (click)="fullscreen()">
          <i class="bx bx-fullscreen"></i>
        </button>
      </div>

    

      <div class="dropdown d-inline-block" ngbDropdown>
        <button type="button" class="btn header-item" ngbDropdownToggle id="page-header-user-dropdown">
          <img class="rounded-circle header-profile-user" src="assets/images/user.png" alt="Header Avatar">
          <span class="d-none d-xl-inline-block ms-1"> {{user?.username}}</span>
          <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
          <!-- item-->
          <a class="dropdown-item" routerLink="/account/profile"><i class="bx bx-user font-size-16 align-middle me-1"></i>
            {{'Profile' | translate}}</a>

            <a class="dropdown-item" routerLink="/account/password"><i class="bx bx-lock font-size-16 align-middle me-1"></i>
              {{'Change Password' | translate}}</a>
         
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" href="javascript:void(0)"  ><span (click)="changeLang('my')">MY</span> | <span (click)="changeLang('en')">EN</span></a>
            <div class="dropdown-divider"></div>  
            <a class="dropdown-item" href="/" ><i
              class="bx bx-home font-size-16 align-middle me-1"></i>
              {{'Back to Home' | translate}}</a>
          <a class="dropdown-item text-danger" href="javascript: void(0);" (click)="logout()"><i
              class="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i>
              {{'Logout' | translate}}</a>
        </div>
      </div>

    

    </div>
  </div>
</header>