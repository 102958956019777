import { Component, OnInit } from '@angular/core';
import { DashboardService } from '../../services/dashboard.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  totalHarvest:number=0;
  data:any;
  year:number = new Date().getFullYear();
  constructor(
		private dashboardService:DashboardService) { }

  ngOnInit(): void {
  }

  


}
